// stylelint-disable scale-unlimited/declaration-strict-value
// stylelint-disable meowtec/no-px
// stylelint-disable no-descending-specificity
// stylelint-disable selector-max-specificity
// stylelint-disable declaration-block-no-duplicate-properties
// stylelint-disable declaration-no-important
// stylelint-disable selector-max-class

.DatePicker {
	position: relative;
	z-index: 100;
	display: inline-block;
}

.DatePicker__input {
	padding: 0.4em 0.8em;
	border: 1px solid #ddd;
	background: #fff;
	font-family: inherit;
	font-size: 12px;
	text-align: center;
}

.DatePicker__input.-rtl {
	direction: rtl;
}

.DatePicker__input::placeholder {
	color: #979797;
}

.DatePicker__calendarContainer.-top + .DatePicker__calendarArrow {
	top: auto;
	bottom: calc(100% + 10px);
	animation: fadeArrowFlipped 0.3s forwards;
	transform: translateY(-2.5rem) rotate(180deg);
}

.DatePicker__calendarContainer {
	position: absolute;
	top: calc(100% + 20px);
	left: 50%;
	transform: translateX(-50%);
}

.DatePicker__calendarContainer.-top {
	top: auto;
	bottom: calc(100% + 20px);
}

.Calendar,
.Calendar * {
	box-sizing: border-box;
	padding: 0;
	margin: 0;
	direction: ltr;
}

.Calendar,
.Calendar.-rtl * {
	direction: rtl;
}

.DatePicker__calendarArrow {
	position: absolute;
	z-index: 10;
	top: calc(100% + 10px);
	right: 0;
	left: 0;
	width: 0;
	height: 0;
	margin: 0 auto;
	border-color: transparent transparent #fff;
	border-style: solid;
	border-width: 0 10px 10px;
}

.Calendar {
	--cl-color-black: #444;
	--cl-color-disabled: #d4d4d4;
	--cl-color-error: #{$danger};
	//background: #fff;
	//box-shadow: 0 1em 4em rgba(0, 0, 0, 0.07);
	//border-radius: 1em;
	position: relative;
	z-index: 10;
	display: flex;
	width: 33em;
	max-width: 90vw;
	min-height: 36.7em;
	flex-direction: column;
	padding-top: 1.2em;
	margin-right: auto;
	margin-left: auto;
	font-size: 10px;
	user-select: none;
}

.DatePicker .Calendar,
.DatePicker__calendarArrow {
	animation: fadeCalendar 0.3s forwards;
	opacity: 0;
	transform: translateY(2.5em);
}

.DatePicker__calendarContainer.-top .Calendar {
	transform: translateY(-2.5em);
}

.Calendar.-noFocusOutline *:focus {
	outline: none !important;
}

.Calendar > :not(.Calendar__footer) button {
	background: transparent;
	cursor: pointer;
	font-family: inherit;
	outline: none;
	-webkit-tap-highlight-color: transparent;
}

.Calendar__header {
	display: flex;
	overflow: hidden;
	align-items: center;
	padding: 2em 2.9em;
	color: var(--cl-color-black);
}

.Calendar__monthArrowWrapper {
	position: relative;
	z-index: 1;
	padding: 3px;
	border: none;
	font-size: 1em;
	line-height: 0;
	opacity: 1;
	transition: 0.2s;
}

.Calendar__monthArrowWrapper:focus {
	outline: 1px dashed rgba(0, 0, 0, 0.4);
	outline-offset: 2px;
}

.Calendar__monthArrowWrapper:disabled,
.Calendar__monthArrowWrapper.-hidden {
	opacity: 0;
	pointer-events: none;
}

.Calendar__monthArrowWrapper.-left {
	transform: rotate(90deg);
}

.Calendar.-rtl .Calendar__monthArrowWrapper.-left {
	transform: rotate(-90deg);
}

.Calendar__monthArrowWrapper.-right {
	transform: rotate(-90deg);
}

.Calendar.-rtl .Calendar__monthArrowWrapper.-right {
	transform: rotate(90deg);
}

.Calendar__monthArrowWrapper:active .Calendar__monthArrow {
	transform: scale(0.7);
}

.Calendar__monthArrow {
	display: block;
	width: 1.7em;
	height: 1.7em;
	background-image: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' width='24' height='24' viewBox='0 0 24 24'%3E%3Cg class='nc-icon-wrapper' fill='%23000000'%3E%3Cdefs stroke='none'%3E%3C/defs%3E%3Cpath class='cls-1' d='M12 23.25V.75' fill='none' stroke='%23000000' stroke-linecap='round' stroke-linejoin='round' stroke-width='1.5px'%3E%3C/path%3E%3Cpath class='cls-2' d='M22.5 11.25L12 .75 1.5 11.25' fill='none' stroke='%23000000' stroke-linecap='round' stroke-linejoin='round' stroke-width='1.5px' fill-rule='evenodd'%3E%3C/path%3E%3C/g%3E%3C/svg%3E");
	background-repeat: no-repeat;
	background-size: 100% 100%;
	border-radius: 50%;
	pointer-events: none;
	transition: var(--animation-duration) transform;
}

.Calendar__monthYearContainer {
	position: relative;
	flex: 1;
}

.Calendar__monthYear {
	position: absolute;
	top: 0;
	bottom: 0;
	left: 50%;
	display: flex;
	align-items: center;
	backface-visibility: hidden;
	font-size: 1.6em;
	font-weight: 500;
	line-height: 1;
	transform: translateZ(0);
	transition: var(--animation-duration);
	will-change: transform, opacity;
}

.Calendar__monthYear.-hiddenNext {
	opacity: 0;
	transform: translateX(50%);
}

.Calendar.-rtl .Calendar__monthYear.-hiddenNext {
	transform: translateX(-150%);
}

.Calendar__monthYear.-hiddenPrevious {
	opacity: 0;
	transform: translateX(-150%);
}

.Calendar.-rtl .Calendar__monthYear.-hiddenPrevious {
	transform: translateX(50%);
}

.Calendar__monthYear.-shown {
	margin-top: auto;
	margin-bottom: auto;
	opacity: 1;
	transform: translateX(-50%);
}

.Calendar__monthYear.-shownAnimated {
	animation: var(--animation-duration) fadeTextToCenter forwards;
}

.Calendar__monthYear > * {
	display: flex;
	align-items: center;
	justify-content: center;
	padding: 0.2em 0.5em;
	border: 1px solid transparent;
	border-radius: 5px;
	font-size: 1.05em;
	transform: translateX(0) scale(0.95);
	transition: var(--animation-duration);
	will-change: transform;
}

.Calendar__monthYear:not(.-shown) > *,
.Calendar__monthYear > *.-hidden {
	cursor: default;
	pointer-events: none;
}

.Calendar__monthText {
	margin-left: -0.3em;
}

.Calendar__yearText:last-child {
	margin-right: -0.3em;
}

.Calendar__monthYear.-shown > *:hover,
.Calendar:not(.-noFocusOutline) .Calendar__monthYear.-shown > *:focus,
.Calendar__monthYear > *.-activeBackground {
	background: #f5f5f5;
}

.Calendar__monthText:hover {
	transform: translateX(-0.2em) scale(0.95);
}

.Calendar.-rtl .Calendar__monthText:hover {
	transform: translateX(0.2em) scale(0.95);
}

.Calendar__yearText:hover {
	transform: translateX(0.2em) scale(0.95);
}

.Calendar.-rtl .Calendar__yearText:hover {
	transform: translateX(-0.2em) scale(0.95);
}

.Calendar__monthYear .Calendar__yearText.-hidden {
	opacity: 0;
	transform: translateX(50%);
}

.Calendar.-rtl .Calendar__monthYear .Calendar__yearText.-hidden {
	transform: translateX(-50%);
}

.Calendar__monthYear .Calendar__monthText.-hidden {
	opacity: 0;
	transform: translateX(-50%);
}

.Calendar.-rtl .Calendar__monthYear .Calendar__monthText.-hidden {
	transform: translateX(50%);
}

.Calendar__monthYear:not(.-shown) > * {
	pointer-events: none;
}

.Calendar__monthSelectorAnimationWrapper,
.Calendar__yearSelectorAnimationWrapper {
	position: absolute;
	bottom: 0;
	display: flex;
	overflow: hidden;
	width: 100%;
	height: 80%;
	align-items: center;
	justify-content: center;
}

.Calendar__monthSelectorWrapper {
	display: flex;
	width: 100%;
	height: 100%;
	align-items: center;
	justify-content: center;
}

.Calendar__monthSelector {
	align-content: center;
	padding: 0 2.5em;
	padding-bottom: 2em;
}

.Calendar__monthSelector,
.Calendar__yearSelector {
	position: relative;
	z-index: 2;
	display: flex;
	height: 100%;
	flex-wrap: wrap;
	background-color: #fff;
	transform: translateY(-150%);
	transition: 0.6s;
	will-change: transform;
}

.Calendar__yearSelectorWrapper {
	width: 100%;
	height: 100%;
}

.Calendar__yearSelectorWrapper::after,
.Calendar__yearSelectorWrapper::before {
	position: absolute;
	left: 0;
	width: 100%;
	height: 5em;
	content: '';
	opacity: 0;
	transition: 0.4s;
	transition-delay: 0.2s;
}

.Calendar__yearSelectorWrapper::after {
	top: -0.1em;
	background-image: linear-gradient(to bottom, #fff, #fff 10%, rgba(245, 245, 245, 0));
}

.Calendar__yearSelectorWrapper::before {
	bottom: 0;
	background-image: linear-gradient(to top, #fff, #fff 10%, rgba(245, 245, 245, 0));
}

.Calendar__yearSelectorWrapper.-faded::after,
.Calendar__yearSelectorWrapper.-faded::before {
	z-index: 3;
	opacity: 1;
}

.Calendar__yearSelector {
	position: relative;
	overflow: scroll;
	width: 100%;
	align-content: flex-start;
	padding: 5em 2em;
	-ms-overflow-style: none;
	scrollbar-width: 0;
}

.Calendar__yearSelector::-webkit-scrollbar {
	display: none;
}

.Calendar__yearSelectorItem {
	display: flex;
	width: 25%;
	justify-content: center;
}

.Calendar__yearSelectorItem:not(:nth-child(-n + 4)) {
	margin-top: 1.5em;
}

.Calendar__yearSelectorText {
	min-width: 85%;
	padding: 0.2em 0.5em;
	border: none;
	border-radius: 8.5px;
	font-size: 1.4em;
}

.Calendar__monthSelector.-open,
.Calendar__yearSelector.-open {
	transform: translateY(0);
}

.Calendar__yearSelectorText:focus,
.Calendar__monthSelectorItemText:focus {
	outline: 1px dashed rgba(0, 0, 0, 0.4);
	outline-offset: 2px;
}

.Calendar__monthSelectorItem {
	display: flex;
	width: calc(100% / 3);
	justify-content: center;
}

.Calendar__monthSelectorItem:not(:nth-child(-n + 3)) {
	margin-top: 2em;
}

.Calendar__monthSelectorItemText {
	min-width: 70%;
	padding: 0.4em;
	border: none;
	border-radius: 8.5px;
	font-size: 1.3em;
	transition: 0.3s;
}

.Calendar__monthSelectorItem:not(.-active) .Calendar__monthSelectorItemText:not(:disabled):hover,
.Calendar__yearSelectorItem:not(.-active) .Calendar__yearSelectorText:not(:disabled):hover {
	background: #f5f5f5;
}

.Calendar__monthSelectorItemText:disabled,
.Calendar__yearSelectorText:disabled {
	cursor: default;
	opacity: 0.5;
}

.Calendar__monthSelectorItem.-active .Calendar__monthSelectorItemText,
.Calendar__yearSelectorItem.-active .Calendar__yearSelectorText {
	background-color: $success;
	color: #fff;
}

.Calendar__weekDays {
	position: relative;
	display: flex;
	justify-content: space-between;
	padding: 0 2.6em;
	margin-bottom: 0.7em;
	color: var(--cl-color-disabled);
	font-size: 1.2em;
}

.Calendar__weekDay {
	display: block;
	width: calc(100% / 7);
	text-align: center;
	text-decoration: none;
}

.Calendar__sectionWrapper {
	position: relative;
	overflow: hidden;
	min-height: 25.8em;
}

.Calendar__section {
	position: absolute;
	top: 0;
	left: 0;
	display: flex;
	width: 100%;
	flex-direction: column;
	padding: 0 3.2em;
	padding-top: 0.5em;
	backface-visibility: hidden;
	color: var(--cl-color-black);
	transform: translateZ(0);
	transition: var(--animation-duration);
	will-change: transform, opacity;
}

.Calendar__section.-hiddenPrevious {
	opacity: 0.5;
	transform: translateX(-90%);
}

.Calendar.-rtl .Calendar__section.-hiddenPrevious {
	transform: translateX(90%);
}

.Calendar__section.-hiddenNext {
	opacity: 0.5;
	transform: translateX(90%);
}

.Calendar.-rtl .Calendar__section.-hiddenNext {
	transform: translateX(-90%);
}

.Calendar__section.-shown {
	opacity: 1;
	transform: translateX(0);
}

.Calendar__section.-shownAnimated {
	animation: var(--animation-duration) FadeContentToCenter forwards;
}

.Calendar__weekRow {
	display: flex;
	width: 100%;
}

.Calendar__day {
	display: block;
	display: flex;
	width: calc(100% / 7);
	align-items: center;
	justify-content: center;
	padding: calc(0.25em - 1px) 0;
	border: 1px solid transparent;
	margin-bottom: 0.3em;
	border-radius: 50%;
	color: rgba(0, 0, 0, 0.8);
	cursor: pointer;
	font-size: 1.6em;
	text-align: center;
	transition: 0.2s;
}

.Calendar__day:focus {
	outline: 1px dashed rgba(0, 0, 0, 0.4);
	outline-offset: 2px;
}

.Calendar__day.-ltr {
	min-height: 2.6em;
	font-size: 1.45em;
}

.Calendar__day.-rtl {
	height: 2.45em;
	font-size: 1.55em;
}

.Calendar__day:not(.-blank):not(.-selectedStart):not(.-selectedEnd):not(.-selectedBetween):not(.-selected):hover {
	background: #eaeaea;
	border-color: transparent;
	border-radius: 50%;
	color: var(--cl-color-black);
}

.Calendar__day.-selected,
.Calendar__day.-selectedStart,
.Calendar__day.-selectedEnd {
	background: $success;
	color: #fff;
}

.Calendar__day.-ltr.-selectedStart {
	border-radius: 0;
	border-bottom-left-radius: 100em;
	border-top-left-radius: 100em;
}

.Calendar__day.-rtl.-selectedStart {
	border-radius: 0;
	border-bottom-right-radius: 100em;
	border-top-right-radius: 100em;
}

.Calendar__day.-selectedBetween {
	background: tint-color($success, 75);
	border-radius: 0;
	color: $success;
}

.Calendar__day.-ltr.-selectedEnd {
	border-bottom-right-radius: 100em;
	border-top-right-radius: 100em;
}

.Calendar__day.-rtl.-selectedEnd {
	border-bottom-left-radius: 100em;
	border-top-left-radius: 100em;
}

.Calendar__day.-weekend:not(.-selected):not(.-blank):not(.-selectedStart):not(.-selectedEnd):not(.-selectedBetween) {
	color: var(--cl-color-error);
}

.Calendar__day.-weekend.-today:not(.-selectedStart):not(.-selectedEnd):not(.-selectedBetween)::after {
	background: var(--cl-color-error);
}

.Calendar__day.-disabled {
	background: transparent !important;
	color: var(--cl-color-disabled) !important;
	cursor: default !important;
}

.Calendar__day.-selected {
	border-radius: 50%;
}

.Calendar__day.-today:not(.-selectedStart):not(.-selectedEnd):not(.-selectedBetween) {
	position: relative;
	color: var(--cl-color-black);
	color: #000;
	font-weight: 600;
}

.Calendar__day.-today:not(.-selectedStart):not(.-selectedEnd):not(.-selectedBetween)::after {
	position: absolute;
	bottom: 0.2em;
	left: 50%;
	display: block;
	width: 0.6em;
	height: 1px;
	background: #000;
	content: '';
	opacity: 0.5;
	transform: translateX(-50%);
	transition: 0.2s;
}

.Calendar__day.-today:hover:not(.-selectedStart):not(.-selectedEnd):not(.-selectedBetween)::after {
	opacity: 0;
}

.Calendar__day.-blank {
	color: transparent;
	cursor: default;
	pointer-events: none;
}

.Calendar__footer {
	position: relative;
	z-index: 1;
}

@keyframes fadeCalendar {
	from {
		opacity: 0;
	}

	to {
		opacity: 1;
		transform: translateY(0);
	}
}

@keyframes fadeArrowFlipped {
	from {
		opacity: 0;
	}

	to {
		opacity: 1;
		transform: translateY(0) rotate(180deg);
	}
}

@keyframes fadeTextToCenter {
	to {
		opacity: 1;
		transform: translateX(-50%);
	}
}

@keyframes FadeContentToCenter {
	to {
		opacity: 1;
		transform: translateX(0);
	}
}

// stylelint-enable scale-unlimited/declaration-strict-value
// stylelint-enable meowtec/no-px
// stylelint-enable no-descending-specificity
// stylelint-enable selector-max-specificity
// stylelint-enable declaration-block-no-duplicate-properties
// stylelint-enable declaration-no-important
// stylelint-enable selector-max-class
